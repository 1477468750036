$color-document-success: #0d8;
$color-document-danger: #d22;
$color-brand-primary: #00c76c;
$color-brand-secondary: #17a19d;
$color-brand-tertiary: #1eb98a;

$color-black: #0a0a0a;
$color-light: #fff;
$color-light-gray: #f8f8f8;
$color-gray: #ececec;
$color-middle-gray: #adadad;
$color-dark-gray: #474747;

$color-link: #2196f3;
$color-dark-link: #4285f4;

$color-webphone-btn-dial: #4285f4;
$color-webphone-btn-answer: #25d366;
$color-webphone-btn-hangup: #ed1c24;

$color-warning: #ffc83f;
$color-error: #e53935;

$avatar-color-default: #607d8b;

$snackbar-bg-success: #d4edda;
$snackbar-border-success: #c3e6cb;
$snackbar-color-success: #155724;
$snackbar-bg-error: #f8d7da;
$snackbar-border-error: #f5c6cb;
$snackbar-color-error: #721c24;
$snackbar-bg-warning: #fff3cd;
$snackbar-border-warning: #ffeeba;
$snackbar-color-warning: #856404;

$color-tab-email: #4dd0e1;
$color-tab-whatsapp: #00d083;
$color-tab-phone: #ff6c22;

// https://www.materialui.co/colors
$avatar-color-list:  #f44336 #e91e63 #9c27b0 #673ab7 #3f51b5 #2196f3 #03a9f4 #00bcd4 #009688 #4caf50 #8bc34a #cddc39 #f9a825 #ffc107 #ff9800 #b71c1c #880e4f #4a148c #311b92 #1a237e #0d47a1 #01579b #006064 #004d40 #1b5e20 #33691e #827717 #f57f17 #ff6f00 #e65100 #bf360c #3e2723;

$voice-unread: #4285f4;
$voice-date: #ff6c22;

$color-chat-text: #6d6d6d;
$color-chat-message-outbound: #bfe;

$color-border-webphone: lighten($color-middle-gray, 15%);
